import api from "@/services/api";

const url = "promotions";

const getPromotions = () => api.get(`${url}/find-all-promotions`);

const createPromotion = (promotion) => api.post(`${url}`, promotion);

export default {
  getPromotions,
  createPromotion,
};
