<template>
  <div class="pa-6 pt-10">
    <v-data-table
      v-model="Promotions"
      :headers="headers"
      :items="Promotions"
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Promoções</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />

          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip>
          <template #activator="{ on, attrs }">
            <v-icon
              class="ml-3"
              small
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template #item.createdAt="{ item }">
        {{ item.creationDate | parseDate }}
      </template>
      <template #item.status="{ item }">
        <v-switch
          v-model="item.status"
          class="ma-0"
          hide-details
          @click="openDialogDelete(item)"
        />
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-Promotions
        v-if="dialog"
        :populate-with="PromotionsToEdit"
        @promotions-edited="editPromotions"
        @promotions-added="addPromotions"
        @close-dialog="closeDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Cuidado!
        </v-card-title>

        <v-card-text v-if="PromotionsToDeactivate.status == 0">
          Você deseja realmente desativar esta Promoção?
        </v-card-text>
        <v-card-text v-else>
          Você deseja realmente ativar esta Promoção?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="(dialogDelete = false), loadPromotions()"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="deactivateItem"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PromotionsService from '../../../services/promotions.service';

export default {
  name: 'Promotions',
  components: { 'form-Promotions': () => import('./Form') },

  data: () => ({
    headers: [
      {
        text: 'Ativo',
        align: 'start',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Nome',
        align: 'start',
        sortable: true,
        value: 'nome',
      },
      {
        text: 'Cidade',
        align: 'start',
        sortable: true,
        value: 'city',
      },
      {
        text: 'Autor',
        align: 'start',
        sortable: false,
        value: 'autor',
      },
      {
        text: 'Data de criação',
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    Promotions: [],
    search: '',
    dialog: false,
    dialogDelete: false,
    PromotionsToEdit: {},
    PromotionsToDeactivate: {},
  }),

  async created() {
    this.loadPromotions();
  },

  methods: {
    async loadPromotions() {
      const data = await PromotionsService.getPromotions();
      this.Promotions = data;
    },

    async closeDialog() {
      this.dialog = false;
      this.PromotionsToEdit = {};
      await this.loadPromotions();
    },

    editItem(item) {
      this.PromotionsToEdit = item;
      this.dialog = true;
    },

    openDialogDelete(item) {
      this.PromotionsToDeactivate = item;
      this.dialogDelete = true;
    },

    async deactivateItem() {
      try {
        this.dialogDelete = false;
        const newStatus = this.PromotionsToDeactivate.status ? 1 : 0;

        await PromotionsService.createPromotion({
          ...this.PromotionsToDeactivate,
          status: newStatus,
        });

        if (newStatus === 0) {
          this.$toast.success('Promoção desativada com sucesso');
        } else {
          this.$toast.success('Promoção ativada com sucesso');
        }

        this.loadPromotions();
      } catch (error) {
        console.error('Erro ao desativar/ativar promoção:', error);
        this.$handleHttpError(error);
      }
    },

    addPromotions(promotion) {
      this.Promotions.push(promotion);
    },

    editPromotions(promotion) {
      const index = this.Promotions.findIndex(
        (listPromotion) => listPromotion.id === promotion.id,
      );
      this.Promotions[index] = promotion;
    },
  },
};
</script>
